@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Gill Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c2c2c;
}

.App {
  margin-top: 20px;
}

.App-logo {
  height: 15vmin;
  min-width: 70px;
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (max-width: 800px) {
  .App-logo {
    min-height: 100px;
    min-width: 100px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  position: sticky;
  position: -webkit-sticky;
  top: -1px; /* required */
}

.divided {
  display: flex;
  align-items: center;
  margin-left: -55vw;
}

@media screen and (max-width: 800px) {
  .divided {
    display: flex;
    align-items: center;
    margin-left: -15vw;
  }
}

.divider {
  flex-grow: 1;
  border-bottom: 2px solid #0f4d92;
}

.nav-divider {
  flex-grow: 1;
  border-bottom: 1px solid #0f4d92;
}

.App-link {
  color: #61dafb;
}

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 7%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  overflow: auto; /* Disable horizontal scroll */
}

.sidenav a {
  padding: 10px 30px 0px 0px;
  text-decoration: none;
  font-size: 13px;
  color: #000;
  display: block;
  text-align: left;
}

.leftnav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 10%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 1; /* Stay at the top */
  left: 0;
  overflow: auto; /* Disable horizontal scroll */
}

.leftnav a {
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 15px;
  color: rgb(44, 44, 44);
  display: block;
  text-align: right;
  font-family: Gill Sans;
}

/* .leftnav a:hover {
  color: #0f4d92;
} */

@media screen and (max-width: 400px) {
  .leftnav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 70px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow: auto; /* Disable horizontal scroll */
  }
  .leftnav a {
    padding: 10px 0px 0px 0px;
    text-decoration: none;
    font-size: 13px;
    color: rgb(44, 44, 44);
    display: block;
    text-align: right;
    font-family: Gill Sans;
  }
}

.footer-container {
  height: 220px;
  background-color: rgb(240, 240, 240);
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.footer {
  display: flex;
  justify-content: space-around;
  text-align: left;
  align-items: left;
  padding-top: 15px;
}

button {
  border: 1px solid black;
  border-radius: 1rem;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 25px;
  padding-left: 25px;
  font-weight: bold;
  margin-right: 2%;
}

button:focus {
  outline: 0;
  border-color: blue;
  color: blue;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c2c2c;
  font-family: "Gill Sans";
  font-weight: normal;
}

.photoTitle {
  right: 0;
  font-size: 9vw;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .photoTitle {
    right: 0;
    font-size: 40px;
    font-weight: bold;
    text-align: left;
  }
}

.cards {
  margin: 0 auto;
  display: grid;
  grid-gap: 3%;
}

.card {
  background-color: rgb(240, 240, 240);
  cursor: pointer;
}

.card2 {
  border: 1px solid rgb(240, 240, 240);
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    cursor: pointer;
  }
}
@media (min-width: 900px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    cursor: pointer;
  }
}

.content {
  margin-top: 2%;
  margin-left: 14vw;
  margin-bottom: 10%;
  margin-right: 5%;
}

@media screen and (max-width: 600px) {
  .content {
    margin-top: 2%;
    margin-left: 23vw;
    margin-bottom: 10%;
    margin-right: 0.1%;
  }
}

.img-container {
  /* max-width: 100%; */
  /* height: 200px; */
  margin-right: 5%;
  margin-left: 1%;
  position: relative;
}

.img {
  width: 100%;
  max-height: 450px;
  text-align: right;
  object-fit: cover;
}

.section-divider {
  flex-grow: 1;
  border-bottom: 2px solid rgb(240, 240, 240);
  margin-right: 5%;
  margin-left: 1%;
}

.img-text {
  position: absolute;
  bottom: -4%;
  color: #fff;
  opacity: 100%;
  padding-right: 20%;
}
.img-text p {
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 0rem 2rem 2rem 0rem;
  padding: 3px 10px 3px 5px;
  width: 15%;
}

.img-text div {
  right: 0;
  font-size: 9vw;
  font-weight: bold;
  text-align: left;
}

/* .small-card-container{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.small-card{
  grid-column: 1 / 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px auto auto;
  position: relative;
}
*/

.section {
  margin-left: 1%;
  margin-right: 5%;
}

.small-img {
  width: 300px;
  height: 200px;
}

@media screen and (max-width: 800px) {
  .small-img {
    width: 300px;
  }
}

/* .small-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 100%;
  height: auto;
} */

/* .media h4 {
  grid-column: 1;
  grid-row: 2;
} */

.dual-section {
  display: flex;
  align-items: left;
  justify-content: space-between;
}

.small-card-container {
  display: flex;
  justify-content: space-between;
}

.card-text {
  color: #000;
  padding: 20px;
  padding-bottom: 0px;
  font-size: 20px;
  font-weight: bold;
}
.card-author {
  color: #000;
  padding: 10px 20px 20px 20px;
}

@media screen and (max-width: 800px) {
  .small-card-container {
    display: flex;
    min-width: 300px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .card-text {
    min-width: 300px;
  }
}
@media screen and (max-width: 800px) {
  .card-author {
    min-width: 300px;
  }
}

.left-side-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  grid-auto-rows: minmax(100px, auto);
}

.small-card-img {
  width: 100%;
  max-height: 300px;
  height: 200px;
  object-fit: cover;
}

.vertical-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 15px;
}

.btn-group {
  cursor: pointer;
  margin-bottom: 5%;
}

.btn-group button {
  border: 1px solid black;
  border-radius: 1rem;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  margin-right: 2%;
  background-color: #fff;
}

.btn-group button:hover {
  background-color: black;
  color: white;
}

.btn-group button:focus {
  outline: 0;
  border-color: blue;
  color: blue;
}

.title {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5%;
  font-family: "Libre Baskerville";
}

.subtitle {
  font-size: 25px;
  padding-bottom: 40px;
  font-weight: normal;
  font-family: "Gill Sans";
}

.small-card-img {
  width: 100%;
  max-height: 300px;
  height: 200px;
  object-fit: cover;
}

.body {
  line-height: 1.4rem;
  margin-bottom: 3%;
}

.author {
  padding-top: 20px;
  font-family: "Gill Sans";
}

