p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c2c2c;
  font-family: "Gill Sans";
  font-weight: normal;
}

.photoTitle {
  right: 0;
  font-size: 9vw;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .photoTitle {
    right: 0;
    font-size: 40px;
    font-weight: bold;
    text-align: left;
  }
}

.cards {
  margin: 0 auto;
  display: grid;
  grid-gap: 3%;
}

.card {
  background-color: rgb(240, 240, 240);
  cursor: pointer;
}

.card2 {
  border: 1px solid rgb(240, 240, 240);
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    cursor: pointer;
  }
}
@media (min-width: 900px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    cursor: pointer;
  }
}

.content {
  margin-top: 2%;
  margin-left: 14vw;
  margin-bottom: 10%;
  margin-right: 5%;
}

@media screen and (max-width: 600px) {
  .content {
    margin-top: 2%;
    margin-left: 23vw;
    margin-bottom: 10%;
    margin-right: 0.1%;
  }
}

.img-container {
  /* max-width: 100%; */
  /* height: 200px; */
  margin-right: 5%;
  margin-left: 1%;
  position: relative;
}

.img {
  width: 100%;
  max-height: 450px;
  text-align: right;
  object-fit: cover;
}

.section-divider {
  flex-grow: 1;
  border-bottom: 2px solid rgb(240, 240, 240);
  margin-right: 5%;
  margin-left: 1%;
}

.img-text {
  position: absolute;
  bottom: -4%;
  color: #fff;
  opacity: 100%;
  padding-right: 20%;
}
.img-text p {
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 0rem 2rem 2rem 0rem;
  padding: 3px 10px 3px 5px;
  width: 15%;
}

.img-text div {
  right: 0;
  font-size: 9vw;
  font-weight: bold;
  text-align: left;
}

/* .small-card-container{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.small-card{
  grid-column: 1 / 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px auto auto;
  position: relative;
}
*/

.section {
  margin-left: 1%;
  margin-right: 5%;
}

.small-img {
  width: 300px;
  height: 200px;
}

@media screen and (max-width: 800px) {
  .small-img {
    width: 300px;
  }
}

/* .small-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 100%;
  height: auto;
} */

/* .media h4 {
  grid-column: 1;
  grid-row: 2;
} */

.dual-section {
  display: flex;
  align-items: left;
  justify-content: space-between;
}

.small-card-container {
  display: flex;
  justify-content: space-between;
}

.card-text {
  color: #000;
  padding: 20px;
  padding-bottom: 0px;
  font-size: 20px;
  font-weight: bold;
}
.card-author {
  color: #000;
  padding: 10px 20px 20px 20px;
}

@media screen and (max-width: 800px) {
  .small-card-container {
    display: flex;
    min-width: 300px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .card-text {
    min-width: 300px;
  }
}
@media screen and (max-width: 800px) {
  .card-author {
    min-width: 300px;
  }
}

.left-side-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  grid-auto-rows: minmax(100px, auto);
}

.small-card-img {
  width: 100%;
  max-height: 300px;
  height: 200px;
  object-fit: cover;
}

.vertical-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 15px;
}

.btn-group {
  cursor: pointer;
  margin-bottom: 5%;
}

.btn-group button {
  border: 1px solid black;
  border-radius: 1rem;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  margin-right: 2%;
  background-color: #fff;
}

.btn-group button:hover {
  background-color: black;
  color: white;
}

.btn-group button:focus {
  outline: 0;
  border-color: blue;
  color: blue;
}
