p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c2c2c;
}

.App {
  margin-top: 20px;
}

.App-logo {
  height: 15vmin;
  min-width: 70px;
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (max-width: 800px) {
  .App-logo {
    min-height: 100px;
    min-width: 100px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  position: sticky;
  position: -webkit-sticky;
  top: -1px; /* required */
}

.divided {
  display: flex;
  align-items: center;
  margin-left: -55vw;
}

@media screen and (max-width: 800px) {
  .divided {
    display: flex;
    align-items: center;
    margin-left: -15vw;
  }
}

.divider {
  flex-grow: 1;
  border-bottom: 2px solid #0f4d92;
}

.nav-divider {
  flex-grow: 1;
  border-bottom: 1px solid #0f4d92;
}

.App-link {
  color: #61dafb;
}

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 7%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  overflow: auto; /* Disable horizontal scroll */
}

.sidenav a {
  padding: 10px 30px 0px 0px;
  text-decoration: none;
  font-size: 13px;
  color: #000;
  display: block;
  text-align: left;
}

.leftnav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 10%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 1; /* Stay at the top */
  left: 0;
  overflow: auto; /* Disable horizontal scroll */
}

.leftnav a {
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 15px;
  color: rgb(44, 44, 44);
  display: block;
  text-align: right;
  font-family: Gill Sans;
}

/* .leftnav a:hover {
  color: #0f4d92;
} */

@media screen and (max-width: 400px) {
  .leftnav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 70px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow: auto; /* Disable horizontal scroll */
  }
  .leftnav a {
    padding: 10px 0px 0px 0px;
    text-decoration: none;
    font-size: 13px;
    color: rgb(44, 44, 44);
    display: block;
    text-align: right;
    font-family: Gill Sans;
  }
}

.footer-container {
  height: 220px;
  background-color: rgb(240, 240, 240);
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.footer {
  display: flex;
  justify-content: space-around;
  text-align: left;
  align-items: left;
  padding-top: 15px;
}

button {
  border: 1px solid black;
  border-radius: 1rem;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 25px;
  padding-left: 25px;
  font-weight: bold;
  margin-right: 2%;
}

button:focus {
  outline: 0;
  border-color: blue;
  color: blue;
}
