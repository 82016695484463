.title {
  font-size: 40px;
  font-weight: bold;
  padding-top: 5%;
  font-family: "Libre Baskerville";
}

.subtitle {
  font-size: 25px;
  padding-bottom: 40px;
  font-weight: normal;
  font-family: "Gill Sans";
}

.small-card-img {
  width: 100%;
  max-height: 300px;
  height: 200px;
  object-fit: cover;
}

.body {
  line-height: 1.4rem;
  margin-bottom: 3%;
}

.author {
  padding-top: 20px;
  font-family: "Gill Sans";
}
